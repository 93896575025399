import Vue from "vue";
// @ts-ignore
import { isNull } from "lodash";
// @ts-ignore
import { mapActions } from "vuex";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import CardActions from "@/components/Content/CardAction.vue";
import i18n from "@/plugins/i18n";
export default Vue.extend({
    name:"ConfirmDeleteReport",
    props:{
        open:{
            type: Boolean,
            default: false
        },
        reportID:{
            type: Number,
            default: null,
        },
        isDeleteAll:{
            type: Boolean,
            default: false
        },
        activeAction:{
            type: Boolean,
            default: false
        },
        ids:{
            type: Object,
            default: function () {
				return {};
			},
        },
        activeData:{
            type: Object,
            default: function () {
				return {};
			},
        }
    },
    components:{
        Dialog,
		CardActions,
    },
    data: () => ({}),
    created(){},
    mounted(){},
    computed:{},
    methods:{
        ...mapActions("loading", ["setLoadingData"]),
        ...mapActions("my_report", ["deleteMyReport"]),

		handleAction(event: { type: string }) {
			const { type } = event;

			switch (type) {
				case "submit":
                    this.handleActions();
					break;
				case "cancel":
                    this.handleCancel(true);
					break;
			}
		},

        async handleCancel(rever: Boolean = false){
            if(this.activeAction)
            {
                this.$emit('cancel-delete', 
                    {   
                        activeActions: true, 
                        id: this.activeData.id, 
                        status: this.activeData.status, 
                        rowIndex: this.activeData.rowIndex,
                        rever: rever
                    }
                );
            }else{
                this.$emit('cancel-delete', null);
            }
        },

        textActive(){
            let status = this.activeData.status;
            if(status===true){
                status = i18n.t("report.scheduler.active");
                return status;
            }else{
                status = i18n.t("report.scheduler.inactive");
                return status;
            }
        },

		async handleActions(){
            await this.deleteReport();
        },
        
        async deleteReport(){
            if(!isNull(this.reportID)){
                try{
                    await this.setLoadingData(TypeLoading.loading);
                    await this.deleteReportListItem(this.reportID);
                    await this.setLoadingData();
                    this.$emit('update-list', this.reportID);
                    this.$emit('cancel-delete', null);
                }catch(err){
                    await this.setLoadingData();
                }
            }
        },
        async deleteAllReport(){
            return await this.$store.dispatch("report/deleteAll", this.ids, {
				root: true,
			});
        },
        async deleteReportListItem(id: any) {
			return await this.$store.dispatch("my_report/deleteMyReport", id, {
				root: true,
			});
		},
        async handleActive() {
			await this.setLoadingData(this.activeData.status ? TypeLoading.enable : TypeLoading.disable);
			const reponse = await this.setActive({
				id: this.activeData.id,
				active: this.activeData.status
			}).catch(async (error: any) => {
                await this.revertActive();
				await this.setLoadingData();
			});
			await this.setLoadingData();
            this.$emit('cancel-delete', null);
		},
        async revertActive(){
            this.handleCancel(true);
        }
    },
    watch:{}
})
