import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Buttons from "../../Commons/Buttons.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption, ResultPaginate } from "@/interfaces/paginated";
// @ts-ignore
import { isUndefined, isNull, isEmpty } from "lodash";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import CustomizeRowObjectGrid from "@/components/Header/Tables/CustomizeRowObjectGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { preparedColumns, preparedData, prepareObject } from "@/views/Admin/MyReport/Logic/Utils";
// @ts-ignore
import { preparedColumnDefsGrid } from "@/utils/CustomizeViewData";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
import omit from 'lodash/omit';
//@ts-ignore
import { getPermisionReport } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";
import { SnackNotificationEntity } from "@/models/Snack";
import { SnactType } from "@/interfaces/snack";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import ModalSendMail from "@/views/Admin/MyReport/Modal/ModalSendMail.vue";
import ModalScheduler from "@/views/Admin/MyReport/Modal/ModalScheduler.vue";
import SchedulerForm from "@/views/Admin/Reports/V2/Dependencies/Schedulers/Form/index.vue";
import router from '@/router'; 
import ConfirmDeleteReport from "../Modal/ConfirmDeleteReport.vue";
import moment from "moment-timezone";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
export default Vue.extend({
name: "ListsMyReport",
props: {},
components: {
    Buttons,
    Alertize,
    GridTable,
    ActionsTable,
    FilterGridAG,
    CustomizeRowObjectGrid,
    NotPermission,
    ModalSendMail,
    ModalScheduler,
    SchedulerForm,
    ConfirmDeleteReport,
    SnackMessage
},
data: () => ({
    dataEntity: preparedData(),
    title: "List My Reports",
    context: null,
    selectionRows: "single",
    snack_notification: new SnackNotificationEntity(),
    dataSchedule: {},

    config: {
      timeout: 5000,
      open: false,
      text: "",
      color: "",
    },

    ShowModal: false,
    ShowModalScheduler: false,
    ids: 0,
    formDelete: {
        all: false,
        id: null,
        open: false,
        active: false,
        ids: null,
        activeData: {
            id: null,
            status: false,
            rowIndex: null,
        },
    },
    updateInterval: null as any,
}),
created(){},
mounted(){
    this.$nextTick(async () => {
        await this.getPaginated();
        this.startPolling();
    });
    this.context = { componentParent: this };
},
beforeDestroy() {
    this.stopPolling();
},
computed:{

    ...mapGetters("profile", ["getAbility"]),

    getResultPaginate(): ResultPaginate {
        return this.$store.state.my_report.result_paginated;
    },

    getEntities(): any[] {
        const result: ResultPaginate = this.getResultPaginate;
        if (
            isUndefined(result) ||
            isNull(result) ||
            isUndefined(result.data) ||
            isNull(result.data)
        ) {
            return [];
        }
        return result.data;
    },

    getConfigColumnDef(){
        return {
            context: this.context,
            entityName: "MyReport",
            eventActions: true,
			flex: 1,
			resizableID: true,
            downloadMyReport: true,
            sendEmailMyReport: true,
            delete_schedule: true,
            scheduler: true,
            checkStatus: false,
			minWidthActions: 170,
			maxWidthActions: 170,
            permission: this.getPermission
        };
    },

    getColumnsDef(){
        return preparedColumnDefsGrid(preparedColumns(), this.getConfigColumnDef);
    },

    gerRowsData(){
        if(isEmpty(this.dataEntity.table.items)) return [];
        return this.dataEntity.table.items;
    },

    getCountData() {
		return this.$t("show.myReport", {
			total: this.getEntities.length,
		});
    },

    canList(){
        return this.getAbility.can(this.getPermission.actions.index_my_report, this.getPermission.subject);
    },

    getPermission(){
        return getPermisionReport();
    }
},
methods:{

    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions('my_report', [ 'deleteMyReport', 'paginatedMyReport', 'getData']),

    updatePaginate(data: any) {
        this.dataEntity.paginated.page = data;
    },

    setFilter(params: { key: string | number; value: any }) {
        this.dataEntity.filters = {};
        this.dataEntity.filters[params.key] =
            typeof params.value !== "undefined" ? params.value : "";
    },

    async getPaginated() {
        try {
            const params = await ParamService.getParams(
                this.dataEntity.paginated,
                this.dataEntity.filters,
                this.dataEntity.options
            );
            
            const response = await this.paginatedMyReport(params);
            
            this.dataEntity.table.items = response.data?.map(item => ({
                ...item,
                report_status: this.$t(`myReport.index.status.${item?.status}`),
                nameMyReport: item.name || "-",
                schedule_start_date: item.schedule_start_date ?? item.start_date,
                schedule_end_date: item.schedule_end_date ?? item.end_date,
                schedule_type: item.end_date ? item.type : "",
                created_at: this.formatDate(item.created_at),
            }));
        } catch (error) {
            console.error('Error en getPaginated:', error);
        } finally {}
    },

    formatDate(date: string | Date | moment.Moment): string {
        if (!date) return '-';
        
        try {
            return moment(date).format(DEFAULT_DATE_TIME_FORMAT);
        } catch (error) {
            console.error('Error formateando fecha:', error);
            return String(date);
        }
    },

    async selectedLimit(limit: number) {
        this.dataEntity.paginated.limit = limit;
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async removeOption() {
        this.dataEntity.filters = {};
        this.dataEntity.options = getDefaultSortingOption("id");
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async removeKeyFilter(key: string) {
        const newFilters: any = omit(this.dataEntity.filters, [key]);
        await this.updateParams({
            filters: newFilters,
            options: this.dataEntity.options,
        }, false);
    },

    async selectedOption(params: {
        options: SortingOption;
        filter: string;
    }) {
        this.setFilter({ key: params.options.sort, value: params.filter });
        this.updatePaginate(1);
        await this.updateParams({
            filters: this.dataEntity.filters,
            options: params.options
        });
    },

    async updateParams(params: { filters: any; options: SortingOption;})
    {
        this.dataEntity.filters = params.filters;
        this.dataEntity.options = params.options;
        this.updatePaginate(1);
        await this.getPaginated();
    },

    async obtenerItemPorId(id: string) {
        return this.dataEntity.table.items.find(item => item.id === id);
    },

    async downloadReport(params: any) {
        try {
            this.setLoadingData(TypeLoading.loading);
    
            const item = await this.obtenerItemPorId(params.id);
    
            if (item && item.filepath) {
                const url = item.filepath;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'report');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                this.snack_notification.setSnackData({
                    message: this.$t("myReport.index.error.file_path"),
                    type: SnactType.ERROR,
                });
            }
        } catch (error) {
            this.snack_notification.setSnackData({
                message: error,
                type: SnactType.ERROR,
            });
        } finally {
            this.setLoadingData();
        }
    },

    async updateList(id){
        this.dataEntity.table.items = this.dataEntity.table.items.filter(item => item.id !== id);
    },

    async cancelDelete(params: {
        activeActions: boolean;
        id: any;
        status: any;
        rowIndex: any;
        rever: Boolean;
    }) {
        this.formDelete.open = false;
    },

    async deleteReportByID(params: any) {
        this.formDelete.open = true;
        this.formDelete.all = false;
        this.formDelete.id = params.id;
    },

    async sendMailMyReport(params: any) {
        this.ShowModalScheduler = false;
        this.ShowModal = true;
        this.formDelete.open = false;
        this.ids = params.id;
    },
    
    async showScheduler(params:any){
        this.ShowModalScheduler = true;
        this.ShowModal = false;
        this.formDelete.open = false;
        this.dataSchedule = this.dataEntity.table.items.find(item => item.id == params.id);
    },

    handleCancel(val) {
        this.ShowModal = false;
        this.ShowModalScheduler = false;
        this.dataSchedule  = {};
    },

    async handleSuccess() {
        this.ShowModal = false;
        this.ShowModalScheduler = false;
        this.dataSchedule  = {};
        await this.getPaginated();
        await this.$nextTick();
    },

    startPolling() {
        this.updateInterval = setInterval(() => {
            this.getPaginated();
        }, 15000);
    },

    stopPolling() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }
},
watch: {
    "dataEntity.paginated.page"(val, old) {
        if (val !== old) {
            this.getPaginated();
        }
    }
}

});
