
import { Component, Vue } from "vue-property-decorator";
import { defineComponent } from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

import { ResourceDataEntity } from "@/interfaces/report";
import { ScheduleResource } from "@/interfaces/reports/v2/scheduler";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { SnackNotificationEntity } from "@/models/Snack";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import DatePicker from "@/components/Content/DatePicker.vue";
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";
import { SnackNotification, SnactType } from "@/interfaces/snack";
import { TypeLoading } from "@/interfaces/loading";import {
	isAfterCompare,
	isMaxDays,
	isRequired,
} from "@/services/rule-services";

export default defineComponent({
  components: {
	CardTextField,
	CardAutocomplete,
	DataPickerStarTime,
	DatePicker,
	CardAction,
	ArrayListItem,
	SnackMessage,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    dataSchedule: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      localShow: this.show,
      dataReportEdit: {},
      startDateScheduler: new Date(),
      valid: false,
      isActionButtonLoading: false,
      snackData: new SnackNotificationEntity(),
	  isLoading: false,
	  cancelTokenSource: null as any
    };
  },
  async mounted(){
	await this.fetchResource('scheduler_type');
  },
  computed: {
    ...mapState('my_report', ['form', 'rules']),
    ...mapGetters('my_report', ['storeSchedule', 'storeResources']),
	...mapGetters('account', ['isAccountMaster']),
	
	...mapGetters("generate_report",["attempResponse"]),

	getIsAccountMaster(): Boolean{
		return !this.isAccountMaster;
	},

    getAttempResponse(): ResponseReportEntity {
      return this.attempResponse;
    },

    getStoreSchedule(): ScheduleEntity {
      return this.storeSchedule;
    },
	
    getStoreResources(): ResourceDataEntity {
      return this.storeResources;
    },

    getMinDate() {
      return this.$moment().format(DEFAULT_DATE_TIME_FORMAT);
    },

    getMinDateEdit() {
      return this.getDateScheduler("schedule_start_date");
    },

	getBtnShows(): CardActionType[] {
		this.isActionButtonLoading = true;

		let buttons = [CardActionType.CANCEL];

		const actionType = this.isEdit
			? CardActionType.UPDATE
			: CardActionType.CREATE;

		buttons.push(actionType);
		this.isActionButtonLoading = false;

		return buttons;
	},

	isEdit(): boolean {
		return this.getStoreSchedule.form.hasID();
	},

	disabledData() {
		return {
			[CardActionType.CREATE]: !this.enableCreateSchedule,
			[CardActionType.UPDATE]: !this.enableEditSchedule,
		};
	},

	enableCreateSchedule(): boolean {
		return this.getAttempResponse.isSuccess() && this.canCreate;
	},

	enableEditSchedule(): boolean {
		return this.getAttempResponse.isSuccess() && this.canUpdate;
	},
	
	getTextActions() {
		return {};
	},

	getEmailsArray(): string[] {
		if (!this.getStoreSchedule?.form?.emails) return [];
		
		if (typeof this.getStoreSchedule.form.emails === 'string') {
			try {
				return JSON.parse(this.getStoreSchedule.form.emails);
			} catch (e) {
				console.warn('Error parsing emails:', e);
				return [];
			}
		}
		
		if (Array.isArray(this.getStoreSchedule.form.emails)) {
			return this.getStoreSchedule.form.emails;
		}

		return [];
	}

  },
  
  methods: {
    ...mapActions('my_report', ['getData', 'getReport', 'getReportType']),
    
    closeModal() {
      this.localShow = false;
      this.$emit("closeModal", false);
    },

	async getReportTypeName(){
		try {
			const typeReportlist = await this.getReportType();
			await this.$nextTick();
			const type = typeReportlist.find(item => item.id === this.dataSchedule?.report_type_id);
			return type.extra;
		} catch (error) {
			console.error('Error en created:', error);
			return null;
		}
	},

    async getDataScheduler() {
      try {
		if(this.dataSchedule.job_schedule_id > 0){
			const result = await this.getData(this.dataSchedule.job_schedule_id);
			if (result) {
			  this.dataReportEdit = result;
			  this.initializeForm();
			}
		}else{
			//const result = await this.getReport(this.dataSchedule.id);
		}
		
		const fileType = [{"id": "csv", "value": "csv"},{"id": "xlsx", "value": "xlsx"}];
		this.getStoreResources.type = fileType || [];
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    },

    initializeForm() {
      try {
        if (this.dataReportEdit) {
			const scheduleData = {
            id: this.dataReportEdit.id,
            report_type: this.dataReportEdit.report_type?.description || '',
			emails: Array.isArray(this.dataReportEdit.emails) 
                    ? this.dataReportEdit.emails 
                    : typeof this.dataReportEdit.emails === 'string'
                        ? JSON.parse(this.dataReportEdit.emails)
                        : [],
            email_subject: this.dataReportEdit.email_subject || '',
            scheduler_type: this.dataReportEdit.scheduler_type?.description.toLowerCase() || '',
            dimensions: JSON.parse(this.dataReportEdit.dimensions) || [],
            metrics: JSON.parse(this.dataReportEdit.metrics) || [],
            filters: this.dataReportEdit.filters || {},
            schedule_start_date: this.dataReportEdit.start_date,
            schedule_end_date: this.dataReportEdit.end_date,
            include_account_email: Boolean(this.dataReportEdit.include_account_email),
			type: this.dataReportEdit.file_type
          };
          this.$store.commit('my_report/SET_SCHEDULER_EDIT', {
			  schedule: scheduleData,
			  report: scheduleData
			});
        }
      } catch (error) {
        console.error('Error en initializeForm:', error);
      }
    },

    emitSubmissionSuccess() {
      this.$emit("submitted-success");
    },

    getDateScheduler(type: "schedule_start_date" | "schedule_end_date") {
      const scheduleDate = this.dataSchedule[type];
      const today = this.$moment();
      let dateMoment = scheduleDate ? this.$moment(scheduleDate) : today;
      
      if (dateMoment.isBefore(today, 'day')) {
        dateMoment = today; 
      }
      
      return dateMoment.format(DEFAULT_DATE_TIME_FORMAT);
    },

	isOoh(params: any) {
		return params === "ooh_report";
	},

	async dispatchStore<T>(moduleFunc: string, data?: T) {
		return await this.$store.dispatch(moduleFunc, data);
	},

    async fetchResource(type: keyof ScheduleResource) {
      try {
		if (this.isLoading) return;
		this.isLoading = true;
        const result = await this.$store.dispatch("my_report/getSchedulerType");
        await this.storeSchedule.resource.setResource(type, result);
      } catch (error) {
        console.error("fetchResource", error);
      }
    },

	handleResetData() {
		this.getStoreSchedule.resetForm();
		this.getStoreSchedule.form.resetForm();
		this.resetValidation();
		this.attempResponse.setSuccess(false);
	},

	async resetValidation(): Promise<boolean> {
		const formComponent: any = this.$refs.form;

		if (
			formComponent &&
			typeof formComponent.resetValidation === "function"
		) {
			return await formComponent.resetValidation();
		}

		return false;
	},

    async handleAction(action: { type: string }): Promise<void> {
		switch (action.type) {
			case CardActionType.CANCEL:
				this.handleResetData();
				this.localShow = false;
				this.$emit("closeModal", false);
				break;
			case CardActionType.CREATE:
				await this.handleSubmit(false);
				break;
			case CardActionType.UPDATE:
				await this.handleSubmit(true);
				break;
		}
	},

	async handleSubmit(isEdit: Boolean = false) {
		try {
			await this.addRules();
			if (!(await this.validate())) return;
			if (!this.getStoreSchedule.form.hasEmail()) {
				return this.snackData.setSnackData({
					message: this.$t("report.scheduler.msgToEmail"),
					type: SnactType.ERROR,
				} as SnackNotification);
			}

			await this.setLoadingData(TypeLoading.loading);
			
			
			this.getStoreSchedule.form.report_type = this.dataSchedule.report_type_extra;
			this.getStoreSchedule.form.start_date = this.dataSchedule.start_date;
			this.getStoreSchedule.form.end_date = this.dataSchedule.end_date;
			if(isEdit){
				this.getStoreSchedule.form.dimensions = JSON.parse(this.dataReportEdit.dimensions);
				this.getStoreSchedule.form.metrics = JSON.parse(this.dataReportEdit.metrics);
			}else{
				const filtros = JSON.parse(this.dataSchedule.filters);
				this.getStoreSchedule.form.data_range = filtros.data_range;
				this.getStoreSchedule.form.job_id = this.dataSchedule.job_id;
				try {
					const dimensionsObj = JSON.parse(this.dataSchedule.metrics);
					const metricsObj = JSON.parse(this.dataSchedule.metrics);
					
					this.getStoreSchedule.form.dimensions = Object.keys(dimensionsObj).filter(key => dimensionsObj[key] === 1);
					this.getStoreSchedule.form.metrics = Object.keys(metricsObj).filter(key => metricsObj[key] === 1);
				} catch (e) {
					console.error('Error parsing dimensions/metrics:', e);
					this.getStoreSchedule.form.dimensions = [];
					this.getStoreSchedule.form.metrics = [];
				}
			}
			const form = this.getStoreSchedule.form;

			const isOoh = this.isOoh(this.dataSchedule.report_type_extra);

			const actionType = isEdit ? "updateSchedule" : "createSchedule";

			const payload = isEdit
				? form.getPayloadUpdate(isOoh)
				: form.getPayloadCreate(isOoh);

			payload.filters = await this.parseAndMapFilters(this.dataSchedule.filters);

			await this.dispatchStore(`report_v2/${actionType}`, payload);

			this.$emit("submission-success");

			this.handleResetData();
			this.$emit("success-Scheduler");
		} catch (error) {
			console.error("handleSubmit", { error: error });
		} finally {
			await this.setLoadingData();
		}
	},

	async parseAndMapFilters(jsonString: string) {
        try {
            const parsedData = JSON.parse(jsonString);
			let mappings = {};
			if(this.isEdit){
				mappings = {
					'account': 'account',
					'advertisers': 'advertisers',
					'campaigns': 'campaigns',
					'creatives': 'creatives',
					'deal_id': 'deal_id',
					'layer_id_filter' : 'layer_id_filter',
					'layer_id_ooh_filter' : 'layer_id_ooh_filter',
					'layer_name' : 'layer_name',
					'line_items': 'line_items',
				};
			}else{
				mappings = {
					'account_id': 'account',
					'advertiser_id': 'advertisers',
					'campaign_id': 'campaigns',
					'creative_id': 'creatives',
					'deal_id': 'deal_id',
					'layer_id_filter_id' : 'layer_id_filter',
					'layer_id_ooh_filter_id' : 'layer_id_ooh_filter',
					'layer_name' : 'layer_name',
					'line_item_id': 'line_items',
				};
			}

            const result = {
                account: [],
                advertisers: [],
                campaigns: [],
                creatives: [],
                deal_id: [],
                layer_id_filter: [],
                layer_id_ooh_filter: [],
                layer_name: [],
                line_items: []
            };

            Object.entries(parsedData).forEach(([key, value]) => {
                const mappedKey = mappings[key];
                if (mappedKey && Array.isArray(value)) {
                    result[mappedKey] = value;
                }
            });

            return result;

        } catch (error) {
            console.error('Error al parsear los filtros:', error);
            return null;
        }
    },

	formatDate(date: string) {
		if (!date) return date;
		return this.$moment(date).format(DEFAULT_DATE_TIME_FORMAT);
	},

	async validate(): Promise<boolean> {
		const formComponent: any = this.$refs.form;

		if (formComponent && typeof formComponent.validate === "function") {
			return await formComponent.validate();
		}

		return false;
	},

	async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	},

	async addRules() {
		const isFutureDate: any = this.validateFutureDate(
			this.getStoreSchedule.form.schedule_start_date
		);

		const isAfter: any = isAfterCompare(
			this.getStoreSchedule.form.schedule_end_date,
			this.getStoreSchedule.form.schedule_start_date
		);
		const isMax: any = isMaxDays(
			new Date(this.getStoreSchedule.form.schedule_end_date),
			new Date(this.getStoreSchedule.form.schedule_start_date),
			90
		);
		this.getStoreSchedule.rules.schedule_end_date = [
			isRequired,
			isAfter,
			isMax,
			isFutureDate,
		];
		this.getStoreSchedule.rules.schedule_start_date = [
			isRequired,
			isFutureDate,
		];
	},

	validateFutureDate(date: any) {
		return (v: any) => {
			const today = new Date().setHours(0, 0, 0, 0);
			const selectedDate = new Date(v).setHours(0, 0, 0, 0);
			return selectedDate >= today || 'La fecha debe ser igual o mayor a hoy';
		};
	},

	handleAppendEmailTo(event: any) {
		const email = this.getStoreSchedule.form.email_to;
		if (email && typeof email === 'string') {
			// Asegúrate de que emails sea un array
			const currentEmails = Array.isArray(this.getStoreSchedule.form.emails)
				? this.getStoreSchedule.form.emails
				: [];
			
			// Añade el nuevo email
			if (!currentEmails.includes(email)) {
				this.getStoreSchedule.form.emails = [...currentEmails, email];
			}
			
			// Limpia el campo email_to
			this.getStoreSchedule.form.email_to = '';
		}
	},
    handleDeleteEmailTo(email: string) {
		if (!Array.isArray(this.getStoreSchedule.form.emails)) {
			this.getStoreSchedule.form.emails = [];
			return;
		}
		
		this.getStoreSchedule.form.emails = this.getStoreSchedule.form.emails.filter(
			e => e !== email
		);
	},
  },
  watch: {
    show(val) {
      this.localShow = val;
	  if(val){
		this.getDataScheduler();
	  }else{
		this.handleResetData();
	  }
	  
    },
  },
});
