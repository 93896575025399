
import { Component, Vue } from "vue-property-decorator";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";

export default defineComponent({
  components: {
    CardTextField,
    CardAutocomplete,
    ArrayListItem,
  },
  mixins: [],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localShow: this.show, // Manejo interno del estado del modal
      valid: false,
    };
  },
  computed: {
    ...mapState("my_report", ["form", "rules"]),
    getemailSubjectRules() {
      return this.rules.email_subject;
    },
    emailToRules() {
      if (this.form.emails.length <= 0) {
        return [
          v => this.form.emails.length > 0 || this. $t("report.scheduler.msgToEmail"),
          v => /.+@.+\..+/.test(v) || "Email must be valid",
        ];
      } else {
        return []; // No se aplican reglas si ya hay correos electrónicos
      }
    },
    getfrom() {
      return this.$store.state.my_report.form;
    },
  },
  methods: {
    ...mapActions("my_report", ["appendEmailTo", "deleteEmailTo", "sendEmail"]),

    closeModalConfirm() {
      this.localShow = false;
      this.$emit("closeModal", false);
    },

    closeModal() {
      this.localShow = false;
      this.resetForm();
      this.$emit("closeModal", false);
    },

    resetForm() {
      
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.$refs.form.reset();
      this.getfrom.emails = [];
    },

    addEmailTo(val: string) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(val)) {
        this.appendEmailTo(val);
        this.$refs.form.resetValidation();
      }
    },

    deleteEmailToAdd(val: string){
      this.deleteEmailTo(val);
    },

    async confirm() {
      if (this.$refs.form.validate()) {
        this.closeModalConfirm();
        this.form.id = this.id;
        await this.sendEmail();
        this.resetForm();
      }
    },
  },
  watch: {
    show(val) {
      this.localShow = val;
    },
  },
});
