// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import i18n from "@/plugins/i18n";
import { isEmpty } from "lodash";

export function preparedColumns(){
    return [
        {
            text: i18n.t('myReport.index.table.id'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "id",
        },
        {
            text: i18n.t('myReport.index.table.report_type'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "report_type_name",
        },
        {
            text: i18n.t('myReport.index.table.report_name'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "nameMyReport",
        },
        {
            text: i18n.t('myReport.index.table.user'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "user_name",
        },
        {
            text: i18n.t('myReport.index.table.created'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "created_at",
        },
        {
            text: i18n.t('myReport.index.table.status'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "report_status",
        },
    ];
};

export function preparedData(){
    return {
        paginated: { page: 1, limit: 25 } as Paginated,
        filters: {},
        options: getDefaultSortingOption("id"),
        table: {
            headers: [],
            items: [],
        }
    }
}

export function itemsReportType(){
    return {
        store_attribution_report: {
            values: ["country", "campaign", "listPois"],
            multiselect: {
                country: false,
                campaign: false,
                listPois: true
            },
        },
        geo_report: {
            values: ["campaign", "start_date", "end_date"],
            multiselect: {
                campaign: false,
            },
        },
        campaign_insight: {
            values: ["country", "campaign"],
            multiselect: {
                country: true,
                campaign: true,
            },
        },
        unique_devices_report: {
            values: ["campaign", "start_date", "end_date"],
            multiselect: {
                campaign: false,
            },
        }
    }
}

export function reportsTypes(){
    return [
        {
            title: i18n.t('reportOnDemand.separators.store_attribution'),
            type: "store_attribution_report",
            keyRoute:"report_demand_store_attribution",
            searchDates: false,
        },
        {
            title: i18n.t('reportOnDemand.separators.gep_report'),
            type: "geo_report",
            keyRoute:"report_demand_geo",
            searchDates: true,
        },
        {
            title: i18n.t('reportOnDemand.separators.campaign'),
            type: "campaign_insight",
            keyRoute:"report_demand_campaign_insight",
            searchDates: false,
        },
        {
            title: i18n.t('reportOnDemand.separators.unique_report'),
            type: "unique_devices_report",
            keyRoute:"report_demand_unique_devices",
            searchDates: true
        }
    ]
}

export function dataReports(){
    return {
        store_attribution_report: {
            model: {
                campaign_id: null,
                country_id: null, 
                list_id: []
            },
            resources:{
                campaign: [],
                country: [], 
                list_pois: []
            },
            loading:{
                campaign: false,
                country: false, 
                list_pois: false
            }
        },
        geo_report: {
            model: {
                campaign_id: null,
                start_date: "",
                end_date: ""
            },
            resources:{
                campaign: [],
            },
            loading:{
                campaign: false,
            }
        },
        campaign_insight: {
            model: {
                campaign_id: [],
                country_id: [], 
            },
            resources:{
                campaign: [],
                country:[]
            },
            loading:{
                campaign: false,
                country: false, 
            }
        },
        unique_devices_report: {
            model: {
                campaign_id: null,
                start_date: "",
                end_date: "" 
            },
            resources:{
                campaign: [],
            },
            loading:{
                campaign: false,
            }
        }
    }
}

/**
 * Tipo de los valores permitidos en el objeto de entrada.
 * Puede ser un string, un número o un array de cualquier tipo.
 */
export type DataValue = string | number | any[];

/**
 * Tipo de datos que se espera en el parámetro 'data'.
 * Es un objeto con claves de tipo string y valores de tipo DataValue.
 */
export interface DataObject {
    [key: string]: DataValue;
}

/**
 * Función para preparar un objeto convirtiendo arrays a strings separados por comas
 * y dejando otros valores sin cambios. Los valores vacíos o no válidos son omitidos.
 * 
 * @param data - Objeto con claves y valores dinámicos
 * @returns Objeto filtrado y transformado
 */
export function prepareObject(text: string = ""): string {
    if(typeof text === "undefined") return "";
    let data: DataObject = {}; 
    try {
        data = JSON.parse(text);
    } catch (error) {
        console.error(error);
        data = {};
    }
    let result: string = "";

    // Iteramos sobre cada entrada del objeto
    for (const [key, value] of Object.entries(data)) {
        // Si el valor es un array, convertirlo en un string con valores separados por comas
        if (Array.isArray(value) && value.length > 0) {
            result = result.concat(`${key}: ${value.join(',')}\t\n`);
        }
        else if (!isEmpty(value)) {
            result = result.concat(`${key}: ${value}\t\n`);
        }
    }

    return result;
}